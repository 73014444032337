body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  background-color: #111176;
}
.navbar-brand, .nav-link, .navbar-text {
  color: white !important;
}
.card-title {
  background: darkgreen;
  color: white;
  padding: 10px;
}
.card-body {
  padding: 0;
}

.create-scenario-button {
  /*
  width: 100%;
  display: block;
  margin-right: 10px;
  margin-top: 10px;
   position: relative;
   */
   height: 38px;
   margin: 10px 5px;
}
.form-text {
  color: red;
  min-height: 21px;
}
button.btn:focus, button.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
.modal-content {
  border-radius: 0 !important;
}
.modal-header{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}